*{
  margin: 0;
}
/* ----------------- app ------------------ */

.app{
  width: 100%;
  height: 100vh;
  display: flex;
}
.card-title h3 {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.75rem;
}

.sidenav{
  width: 20%;
  height: 100vh;
  box-shadow: 0 5px 10px rgba(0,0,0,0.5);
  background-color: #303030;
  padding-top: 30px;
  overflow-y: scroll;
}
.sidenav>ul>li{
  color: #f5f5f5;
  width: 85%;
  background: #2c51a3;
  margin-bottom: 8px;
  font-size: 14px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.sidenav ul > li.empty-menu {
  background: none;
  list-style: none;
  margin-top: 16px;
}

.sidenav>ul>li:hover{
  font-weight: bold;
  color: #f5f5f5;

}

.logo-turbo {
  text-align: center;
  margin-top: 10px;
  margin: auto;
  margin-bottom: 30px;
  padding: 20px;
  width: 170px;
  height: 150px;
  border-radius: 50%;
  background-color: #fff;
}

.content{
  width: 80%;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
}
.content>h1{
  margin-bottom: 20px;
  position: relative;
}
.content>h1:before{
  content: '';
  width: 100%;
  height: 2px;
  background: #303030;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(10px);
}

.logout {
  position: absolute;
  bottom: 15px;
  right: 30px;
  border-radius: 10px;
  padding: 5px;
}

.logout a {
  color: white;
}

.logout:hover {
  transform: scale(1.1);
}

.logout a:hover {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 500px){
  .app{
    display: block;
  }
  .sidenav{
    width: 100%;
    height: 28%;
    padding-top: 5px;
  }
  .sidenav>ul{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
  }
  .sidenav>ul>li{
    margin-right: 20px;
    width: 300px !important;
    font-size: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .content{
    width: 100%;
    height: auto;
  }
}


/* ------------------- Card ----------------------- */


.card_box{
  width: 90%;
  margin-bottom: 50px;
}

@media screen and (max-width: 500px){
  .card_box{
    width: 90%;
  }
}


/* ------------------ consolidated ----------------------- */


.info{
  margin-top: 50px;
}


.data_user{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.data_user_box{
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
}
.data_user_box>h3{
  font-weight: bold;
}

/* -------------------------- LOGIN ---------------------- */

.login{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login>img{
  width: 20%;
  margin-bottom: 20px;
}

.card_login{
  padding: 25px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.5);
  border-radius: 15px;
}

@media screen and (max-width: 500px){
  .login>img{
    width: 65%;
  }
}
